import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ArrowDown, ArrowUp } from '../assets/ArrowDropDown'
import BarChart from '../assets/BarChart'
import Loading from '../assets/Loading'
import NineDots from '../assets/NineDots'
import RadioButtonChecked from '../assets/RadioButtonChecked'
import UserTableEmpty from '../assets/UserTableEmpty'
import BackToPage from '../components/BackToPage'
import Dropdown from '../components/Dropdown'
import {
  ApplicationCell,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
} from '../components/Table'
import {
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingSmallest,
} from '../components/Text'
import useAuth from '../hooks/useAuth'
import { ContractCard } from '../Overview/Product'
import { ProfileHeaderLarge } from '../pages/Profile'
import theme from '../Theme'
import {
  dateToShortFormat,
  getDateDiff,
  getPastNMonths,
  getThisMonth,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { useFlags } from 'launchdarkly-react-client-sdk'

const CardsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: '16px',
  width: '100%',
  marginBottom: '24px',
})

export type UserInfo = {
  first_name: string
  last_name: string
  email: string
  title: string
  department: string
  profile_picture: string
  num_apps: number
  num_visits: number
  prev_num_apps: number
  prev_num_visits: number
  last_visit: string
  apps: App[]
}

type App = {
  id: number
  name: string
  logo_url: string
  category: string
  num_visits: number
}

const User = () => {
  const [user, setUser] = useState<UserInfo | null | undefined>(undefined)
  const [monthsAgo, setMonthsAgo] = useState(0)
  const monthOptions = getPastNMonths(6)
  const { showHris } = useFlags()

  const onRangeChange = (selected: string) => {
    setMonthsAgo(monthOptions.indexOf(selected))
  }

  const { permissions } = useAuth()
  const { user_hash } = useParams()
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    const getUser = async () => {
      const reqPath = addImpersonationParams(
        `/users/${user_hash}?monthsAgo=${monthsAgo}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch user data`, ToastType.ERROR)
        setUser(null)
        return
      }
      setUser(resp)
    }
    getUser()
  }, [user_hash, monthsAgo, reqOrgId, reqUserId, impersonate])

  if (user === undefined) {
    return <Loading large />
  }
  if (user === null || !permissions || !permissions.see_users) {
    return <HeadingLarge>User not found. Please contact support</HeadingLarge>
  }

  return (
    <>
      <BackToPage path={'/users'} pageName={'Users'} />
      <ProfileHeaderLarge profile={user} />
      {showHris ? <OverviewCard user={user} /> : <StatusCards user={user} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: '16px',
        }}
      >
        <HeadingMedium color="textDefault">
          Apps Used
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {user.apps.length > 0 && ` (${user.apps.length})`}
          </span>
        </HeadingMedium>
        <Dropdown
          defaultValue={monthOptions[0]}
          options={monthOptions}
          onSelect={onRangeChange}
        />
      </div>
      <UsageTable apps={user.apps} />
    </>
  )
}
const Card = styled('div')({
  minHeight: '100px',
  display: 'flex',
  gap: '11px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '8px',
  backgroundColor: theme.color.white,
  padding: '16px 24px',
  marginBottom: '33px',
  flexGrow: 1,
})

const AppsUsed = ({ user, curMonth }: { user: UserInfo; curMonth: string }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {user.num_apps === 0 ? (
      '-'
    ) : (
      <>
        {user.num_apps.toString()}
        {user.num_apps > user.prev_num_apps ? (
          <>
            <ArrowUp />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              (+
              {user.num_apps - user.prev_num_apps} in {curMonth})
            </HeadingSmallest>
          </>
        ) : (
          <>
            <ArrowDown />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              ({user.num_apps - user.prev_num_apps} in {curMonth})
            </HeadingSmallest>
          </>
        )}
      </>
    )}
  </div>
)

const TotalVisits = ({
  user,
  curMonth,
}: {
  user: UserInfo
  curMonth: string
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {user.num_visits === 0 ? (
      '-'
    ) : (
      <>
        {user.num_visits.toString()}
        {user.num_visits > user.prev_num_visits ? (
          <>
            <ArrowUp />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              (+
              {user.num_visits - user.prev_num_visits} in {curMonth})
            </HeadingSmallest>
          </>
        ) : (
          <>
            <ArrowDown />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              ({user.num_visits - user.prev_num_visits} in {curMonth})
            </HeadingSmallest>
          </>
        )}
      </>
    )}
  </div>
)

const UserStatus = ({
  user,
  isActive,
}: {
  user: UserInfo
  isActive: boolean
}) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    {isActive ? (
      <>
        <RadioButtonChecked
          style={{ color: theme.color.successGreen, height: '24px' }}
          large
        />
        <span>Active</span>
      </>
    ) : (
      <>
        <RadioButtonChecked
          style={{ color: theme.color.errorRed, height: '24px' }}
          large
        />
        <span>Inactive</span>
        {user.last_visit.length === 0 ? (
          ''
        ) : (
          <HeadingSmallest color="textSubdued">
            (since {dateToShortFormat(user.last_visit)})
          </HeadingSmallest>
        )}
      </>
    )}
  </div>
)

const Title = styled(HeadingSmallest)({
  color: theme.color.textSubdued,
  borderBottom: `1px solid ${theme.color.border}`,
  height: '40px',
})

const OverviewCard = ({ user }: { user: UserInfo }) => {
  const isActiveUser = () => {
    return (
      user.last_visit.length > 0 &&
      getDateDiff(new Date(), new Date(user.last_visit)) < 14
    )
  }

  const curMonth = getThisMonth().split(' ')[0] // e.g. `Mar`
  return (
    <CardsWrapper>
      <Card>
        <HeadingSmall color="textDefault" style={{ flexGrow: 1 }}>
          Overview
        </HeadingSmall>
        <div
          style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 250px)' }}
        >
          <Title>Department</Title>
          <Title>Title</Title>
          <Title>Applications</Title>
          <Title>Total Visits</Title>
          <Title>User Status</Title>
          <HeadingSmallest>{user.department || '-'}</HeadingSmallest>
          <HeadingSmallest>{user.title || '-'}</HeadingSmallest>
          <AppsUsed user={user} curMonth={curMonth} />
          <TotalVisits user={user} curMonth={curMonth} />
          <UserStatus user={user} isActive={isActiveUser()} />
        </div>
      </Card>
    </CardsWrapper>
  )
}

const StatusCards = ({ user }: { user: UserInfo }) => {
  const isActiveUser = () => {
    return (
      user.last_visit.length > 0 &&
      getDateDiff(new Date(), new Date(user.last_visit)) < 14
    )
  }

  const curMonth = getThisMonth().split(' ')[0] // e.g. `Mar`

  return (
    <CardsWrapper>
      <ContractCard
        Icon={NineDots}
        title={'Applications'}
        value={<AppsUsed user={user} curMonth={curMonth} />}
      />
      <ContractCard
        Icon={BarChart}
        title={<>Total Visits </>}
        value={<TotalVisits user={user} curMonth={curMonth} />}
      />
      <ContractCard
        Icon={RadioButtonChecked}
        title={'User Status'}
        value={<UserStatus user={user} isActive={isActiveUser()} />}
      />
    </CardsWrapper>
  )
}

const EmptyUsageTable = ({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <div
        style={{
          borderTop: `1px solid ${theme.color.border}`,
          borderBottom: `1px solid ${theme.color.border}`,
          margin: '15px 0',
          padding: '24px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UserTableEmpty />
        <HeadingSmall>No data found for the selected month</HeadingSmall>
      </div>
    </TableCell>
  </TableRow>
)

const UsageTable = ({ apps }: { apps: App[] }) => (
  <TableWrapper cellSpacing={0}>
    <TableHead>
      <TableRow>
        <TableCell mw="180px">Application</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Visits</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {apps.length === 0 ? (
        <EmptyUsageTable colSpan={3} />
      ) : (
        apps
          .sort((a, b) => b.num_visits - a.num_visits)
          .map((app, i) => (
            <TableRow key={i}>
              <ApplicationCell
                id={app.id}
                logo={app.logo_url}
                name={app.name}
              />
              <TableCell mw="100px">{app.category}</TableCell>
              <TableCell>{app.num_visits > 0 ? app.num_visits : '-'}</TableCell>
            </TableRow>
          ))
      )}
    </TableBody>
  </TableWrapper>
)

export default User
