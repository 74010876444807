import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'
import styled, { StyleSheetManager, ThemeProvider } from 'styled-components'
import FourOhFour from './404'
import NavBar from './components/NavBar'
import ActiveContractsPage from './Contracts'
import Integrations from './Integrations'
import Login from './pages/Login'
import LoginCallback from './pages/Login/LoginCallback'
import Overview from './Overview'
import ProductDetails from './Overview/Product'
import theme from './Theme'
import useAuth, { AuthProvider } from './hooks/useAuth'
import useImpersonation, {
  ImpersonationProvider,
} from './hooks/useImpersonation'
import Users from './Users'
import PasswordSet from './pages/PasswordSet'
import ProfilePage from './pages/Profile'
import User from './Users/User'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import ReportPage from './pages/Report'
import SettingsPage from './pages/Settings'
import Loading from './assets/Loading'
import ImpersonationPage from './pages/Impersonation'
import {
  LDContext,
  useLDClient,
  withLDProvider,
} from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import UpcomingContractsPage from './Contracts/UpcomingRenewals'
import ArchivedContractsPage from './Contracts/ArchivedContracts'
import PromptPage from './pages/Prompt'
import LoginOkta from './pages/Login/LoginOkta'

const PageWrapper = styled('div')`
  padding: 24px 50px;
`

function RequiredAuth({ children }: { children: JSX.Element }) {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />
  }
  return children
}

function Router() {
  const { user, permissions, canImpersonate, loadingInitial } = useAuth()

  const { reqOrgId } = useImpersonation()
  const ldClient = useLDClient()

  useEffect(() => {
    if (!ldClient) return

    const getOrgID = () => {
      if (reqOrgId !== -1) {
        return reqOrgId.toString()
      }
      if (user) {
        return user.org_id.toString()
      }
      return '-1'
    }
    const orgCtx: LDContext = { kind: 'organization', key: getOrgID() }
    ldClient.identify(orgCtx)
  }, [ldClient, reqOrgId, user])

  if (loadingInitial) {
    return <Loading large />
  }

  return (
    <Routes>
      <Route path="login/callback" element={<LoginCallback />} />

      <Route
        index
        element={
          <RequiredAuth>
            <Overview />
          </RequiredAuth>
        }
      />

      <Route
        path="product/:product_id"
        element={
          <RequiredAuth>
            <ProductDetails />
          </RequiredAuth>
        }
      />

      <Route
        path="users/:user_hash"
        element={
          <RequiredAuth>
            <User />
          </RequiredAuth>
        }
      />
      <Route
        path="users"
        element={
          <RequiredAuth>
            <Users />
          </RequiredAuth>
        }
      />

      <Route
        path="integrations/*"
        element={
          permissions && permissions.see_integrations ? (
            <RequiredAuth>
              <Integrations />
            </RequiredAuth>
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="contracts/active"
        element={
          <RequiredAuth>
            <ActiveContractsPage />
          </RequiredAuth>
        }
      />
      <Route
        path="contracts/upcoming"
        element={
          <RequiredAuth>
            <UpcomingContractsPage />
          </RequiredAuth>
        }
      />
      <Route
        path="contracts/archive"
        element={
          <RequiredAuth>
            <ArchivedContractsPage />
          </RequiredAuth>
        }
      />

      <Route
        path="profile"
        element={
          <RequiredAuth>
            <ProfilePage />
          </RequiredAuth>
        }
      />

      <Route
        path="settings"
        element={
          permissions && permissions.see_settings ? (
            <RequiredAuth>
              <SettingsPage />
            </RequiredAuth>
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="impersonate"
        element={
          canImpersonate ? (
            <RequiredAuth>
              <ImpersonationPage />
            </RequiredAuth>
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="insights"
        element={
          <RequiredAuth>
            <ReportPage />
          </RequiredAuth>
        }
      />

      <Route
        path="ask"
        element={
          <RequiredAuth>
            <PromptPage />
          </RequiredAuth>
        }
      />

      <Route path="login" element={<Login />} />
      <Route path="login/okta" element={<LoginOkta />} />
      <Route path="password_set" element={<PasswordSet />} />
      <Route path="forget_password" element={<ForgetPassword />} />
      <Route path="reset_password" element={<ResetPassword />} />
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  )
}

export function App() {
  const shouldForwardProp = (prop: string) => {
    if (
      [
        'mw',
        'vertical',
        'inline',
        'isActive',
        'isOpen',
        'rightAlign',
        'alert',
        'marginBottom',
        'isDiminish',
      ].includes(prop)
    ) {
      return false
    }
    return true
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <ImpersonationProvider>
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <NavBar />
              <PageWrapper>
                <Router />
              </PageWrapper>
            </StyleSheetManager>
          </ImpersonationProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID as string,
})(App)
