import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const Copy = ({
  style,
  onClick,
}: {
  style?: CSSProperties
  onClick?: () => void
}) => (
  <SVGWrapper style={style} onClick={onClick}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1365 21.5946C12.6477 21.5946 12.2339 21.4243 11.8952 21.0838C11.5565 20.7432 11.3871 20.3272 11.3871 19.8357V8.75889C11.3871 8.26738 11.5565 7.85135 11.8952 7.51081C12.2339 7.17027 12.6477 7 13.1365 7H21.2506C21.7394 7 22.1532 7.17027 22.4919 7.51081C22.8306 7.85135 23 8.26738 23 8.75889V19.8357C23 20.3272 22.8306 20.7432 22.4919 21.0838C22.1532 21.4243 21.7394 21.5946 21.2506 21.5946H13.1365ZM13.1365 20.1351H21.2506C21.3251 20.1351 21.3933 20.1039 21.4552 20.0415C21.5173 19.9792 21.5484 19.9106 21.5484 19.8357V8.75889C21.5484 8.68397 21.5173 8.61538 21.4552 8.55311C21.3933 8.49068 21.3251 8.45946 21.2506 8.45946H13.1365C13.062 8.45946 12.9938 8.49068 12.9319 8.55311C12.8698 8.61538 12.8387 8.68397 12.8387 8.75889V19.8357C12.8387 19.9106 12.8698 19.9792 12.9319 20.0415C12.9938 20.1039 13.062 20.1351 13.1365 20.1351ZM9.74944 25C9.26056 25 8.84677 24.8297 8.50806 24.4892C8.16935 24.1486 8 23.7326 8 23.2411V10.7048H9.45161V23.2411C9.45161 23.316 9.48266 23.3846 9.54476 23.4469C9.60669 23.5093 9.67492 23.5405 9.74944 23.5405H19.3151V25H9.74944Z"
        fill="#3A393B"
      />
    </svg>
  </SVGWrapper>
)

export default Copy
