import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const Checkmark = ({
  style,
  onClick,
}: {
  style?: CSSProperties
  onClick?: () => void
}) => (
  <SVGWrapper style={style} onClick={onClick}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5493 21.6537L8.21484 16.3192L9.28384 15.25L13.5493 19.5155L22.7148 10.35L23.7838 11.4192L13.5493 21.6537Z"
        fill="#3A393B"
      />
    </svg>
  </SVGWrapper>
)
export default Checkmark
